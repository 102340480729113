@import "/assets/styles/utils.scss";
@import "/assets/styles/typography.scss";

.lead-paragraph-module {
    text-align: center;
    max-width: 56em;
    margin-left: auto;
    margin-right: auto;

    h2, h3, h4 {
        strong {
            font-weight: 600
        }
    }
    h2 {
        @extend .heading-200;
    }
    h3 {
        @extend .heading-100;
    }
    h4 {
        @extend .heading-50;
    }

    &__button {
        margin-top: em(40, 14);
    }
}

:global([class*="Module_module--collapse-margin-bottom"]) .lead-paragraph-module {
    padding-bottom: 34px;
}

@use 'sass:math';
@import '/assets/styles/utils.scss';

.text-grid-module {
    $text-grid-module: &;
    &__title,
    &__body-text {
        margin-bottom: 30px;
        @media (min-width: 1024px) {
            max-width: 50vw;
        }
    }
    &__body-text {
        margin-bottom: 48px;
    }

    &__list {
        align-items: start;
        display: grid;
        column-gap: em(40);
        padding: 20px 0;
        margin-bottom: 1em;

        // grid-template-columns: repeat(1, minmax(0, 1fr));
        @media (max-width: 700px) {
            &:not(&--has-images) > * {
                grid-area: auto !important;
            }
        }

        &--has-images {
            // column-gap: em(16);
            // grid-template-areas:
            //     'item1Media item2Media'
            //     'item1Text item2Text'
            //     'item3Media item4Media'
            //     'item3Text item4Text'
            //     'item5Media item6Media'
            //     'item5Text item6Text'
            //     'item7Media item8Media'
            //     'item7Text item8Text'
            //     'item9Media item10Media'
            //     'item9Text item10Text'
            //     'item11Media item12Media'
            //     'item11Text item12Text'
            //     'item13Media item14Media'
            //     'item13Text item14Text'
            //     'item15Media item16Media'
            //     'item15Text item16Text'
            //     'item17Media item18Media'
            //     'item17Text item18Text'
            //     'item19Media item20Media'
            //     'item19Text item20Text'
            //     'item21Media item22Media'
            //     'item21Text item22Text';
            // grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (max-width: 768px) {
            &--mobile-columns-1 {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            &--mobile-columns-2, &--has-images {
                grid-template-areas:
                'item1Media item2Media'
                'item1Text item2Text'
                'item3Media item4Media'
                'item3Text item4Text'
                'item5Media item6Media'
                'item5Text item6Text'
                'item7Media item8Media'
                'item7Text item8Text'
                'item9Media item10Media'
                'item9Text item10Text'
                'item11Media item12Media'
                'item11Text item12Text'
                'item13Media item14Media'
                'item13Text item14Text'
                'item15Media item16Media'
                'item15Text item16Text'
                'item17Media item18Media'
                'item17Text item18Text'
                'item19Media item20Media'
                'item19Text item20Text'
                'item21Media item22Media'
                'item21Text item22Text';
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
            &--boxed {
                #{$text-grid-module}--mobile-columns-1 {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
                #{$text-grid-module}--mobile-columns-2 {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
        }

        @media (min-width: 700px) {
            &--has-images {
                column-gap: em(20);
            }
            grid-template-areas:
                'item1Media item2Media'
                'item1Text item2Text'
                'item3Media item4Media'
                'item3Text item4Text'
                'item5Media item6Media'
                'item5Text item6Text'
                'item7Media item8Media'
                'item7Text item8Text'
                'item9Media item10Media'
                'item9Text item10Text'
                'item11Media item12Media'
                'item11Text item12Text'
                'item13Media item14Media'
                'item13Text item14Text'
                'item15Media item16Media'
                'item15Text item16Text'
                'item17Media item18Media'
                'item17Text item18Text'
                'item19Media item20Media'
                'item19Text item20Text'
                'item21Media item22Media'
                'item21Text item22Text'
                'item23Media item24Media'
                'item23Text item24Text';
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: 1100px) {
            column-gap: em(60);
            &--columns-3,
            &--columns-4,
            &--columns-5 {
                grid-template-areas:
                    'item1Media item2Media item3Media'
                    'item1Text item2Text item3Text'
                    'item4Media item5Media item6Media'
                    'item4Text item5Text item6Text'
                    'item7Media item8Media item9Media'
                    'item7Text item8Text item9Text'
                    'item10Media item11Media item12Media'
                    'item10Text item11Text item12Text'
                    'item13Media item14Media item15Media'
                    'item13Text item14Text item15Text'
                    'item16Media item17Media item18Media'
                    'item16Text item17Text item18Text'
                    'item19Media item20Media item21Media'
                    'item19Text item20Text item21Text'
                    'item22Media item23Media item24Media'
                    'item22Text item23Text item24Text';
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }

        @media (min-width: 1300px) {
            &--columns-4 {
                grid-template-areas:
                    'item1Media item2Media item3Media item4Media'
                    'item1Text item2Text item3Text item4Text'
                    'item5Media item6Media item7Media item8Media'
                    'item5Text item6Text item7Text item8Text'
                    'item9Media item10Media item11Media item12Media'
                    'item9Text item10Text item11Text item12Text'
                    'item13Media item14Media item15Media item16Media'
                    'item13Text item14Text item15Text item16Text'
                    'item17Media item18Media item19Media item20Media'
                    'item17Text item18Text item19Text item20Text'
                    'item21Media item22Media item23Media item24Media'
                    'item21Text item22Text item23Text item24Text';
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
            &--columns-5 {
                grid-template-areas:
                    'item1Media item2Media item3Media item4Media item5Media'
                    'item1Text item2Text item3Text item4Text item5Text'
                    'item6Media item7Media item8Media item9Media item10Media'
                    'item6Text item7Text item8Text item9Text item10Text'
                    'item11Media item12Media item13Media item14Media item15Media'
                    'item11Text item12Text item13Text item14Text item15Text'
                    'item16Media item17Media item18Media item19Media item20Media'
                    'item16Text item17Text item18Text item19Text item20Text'
                    'item21Media item22Media item23Media item24Media item25Media'
                    'item21Text item22Text item23Text item24Text item25Text';
                grid-template-columns: repeat(5, minmax(0, 1fr));
            }
        }

        &--boxed {
            column-gap: 20px;
        }
    }

    &__item__boxed-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        // background-color: beige;
        align-self: stretch;
        padding: 16px 8px;
        margin-bottom: 20px;
        @media (min-width: 768px) {
            padding: 24px 16px;
        }

        @media (hover: hover) {
            &:hover {
                :global(.arrow-link--right) {
                    --arrow-translate: 4px;
                }
            }
        }

        #{$text-grid-module}__list--mobile-columns-1 & {
            @media (max-width: 768px) {
                padding: 24px;
                h3 {
                    font-size: em(18);
                }
                #{$text-grid-module}__item-media {
                    width: 100%;
                    max-height: 20vh;
                }
            }
        }
        #{$text-grid-module}__list--mobile-columns-2 & {
            @media (max-width: 768px) {
                h3 {
                    font-size: em(13);
                }
            }
        }

        border-radius: 8px;
        background-color: white;
        overflow: hidden;

        #{$text-grid-module}__item-text {
            position: relative;
            z-index: 10;
            padding-top: 0 !important;
        }
        #{$text-grid-module}__item-media {
            order: 2;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-self: center;

            &--clutch-to-bottom {
                position: relative;
                bottom: -16px;
                @media screen and (min-width: 768px) {
                    bottom: -24px;
                }
            }
        }

    }

    &__item-media {
        align-self: flex-end;

        &:hover + div {
            :global(.arrow-link--right) {
                --arrow-translate: 4px;
            }
        }
    }
    &__item-text {
        margin-bottom: em(56);
        @media (max-width: 768px) {
            margin-bottom: em(40);
        }
        &:hover :global(.arrow-link--right) {
            --arrow-translate: 4px;
        }
    }
    &__list--has-images {
        #{$text-grid-module}__item-text {
            padding-top: 24px;
        }
    }

    &__cta {
        text-align: center;
    }
    &__disclaimer {
        font-size: 0.72em;
        color: var(--gray-100);
        line-height: 160%;
        padding-top: 4px;
        a {
            font-weight: 400;
        }
    }

    &__item__click-catcher {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 100;
    }
    &__item__link {
        display: block;
        --color: inherit;
        width: 100%;
        height: 100%;
    }

    &__item__angle {
        display: none;
        position: absolute;
        top: 100%;
        color: var(--fog);
        left: 0;
        right: 0;

        &--dark-theme {
            @media (hover: hover) {
                color: var(--primary-white);
            }
        }
    }

    &__item__title {
        margin-bottom: 0.4em;
    }

    &__item__cta {
        display: block;

        :global(.segment-business) & {
            // color: var(--accent);
        }

        svg {
            height: 1.25em;
            width: 1.25em;
            margin-left: 6px;
            vertical-align: middle;
        }
    }

    &__item__image {
        &--large {
            width: 100%;
            height: 100%;
        }

        &--small {
            width: 4em;
        }
        &--background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            > div {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
                // width: 100%;
                // height: 100%;
            }
        }
        :global(video) {
            object-fit: cover;
        }
    }
}
